function format(html) {
  var tab = "\t";
  var result = "";
  var indent = "";

  html.split(/>\s*</).forEach(function (element) {
    if (element.match(/^\/\w/)) {
      indent = indent.substring(tab.length);
    }

    result += indent + "<" + element + ">\r\n";

    if (element.match(/^<?\w[^>]*[^\/]$/) && !element.startsWith("input")) {
      indent += tab;
    }
  });

  return result.substring(1, result.length - 3);
}

function createTable(excelData) {
  // split into rows
  let excelRow = excelData.split(String.fromCharCode(10));

  // split rows into columns
  for (let i = 0; i < excelRow.length; i++) {
    excelRow[i] = excelRow[i].split(String.fromCharCode(9));
  }

  // start to create the HTML table
  var myTable = document.createElement("table");
  myTable.setAttribute("id", "myTable");
  var myTbody = document.createElement("tbody");

  // Loop over the rows
  for (let i = 0; i < excelRow.length - 1; i++) {
    // create a row in the HTML table
    var myRow = document.createElement("tr");

    // Loop over the columns and add TD to the TR
    for (let j = 0; j < excelRow[i].length; j++) {
      // Loop over the row columns
      if (excelRow[i][j].length !== 0) {
        var myCell = document.createElement("td");
        myCell.innerHTML = excelRow[i][j];
      }
      myRow.appendChild(myCell);
    }
    myTbody.appendChild(myRow);
  }
  myTable.appendChild(myTbody);

  return myTable.outerHTML;
}

const utils = {
  format,
  createTable
};

export default utils;
