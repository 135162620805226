import { useEffect, useState } from "react";
import "./App.css";
import utils from "./utils/clipboardToHtml";
import useCopyToClipboard from "./hooks/useCopyToClipboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [value, setValue] = useState("");
  const [htmlTable, setHtmlTable] = useState("");
  const [copied, copyToClipboard] = useCopyToClipboard();
  const notify = () => toast("Copied to clipboard!");

  const convertToHtmlTable = () => {
    let html = utils.createTable(value);
    html = utils.format(html);
    setHtmlTable(html);
  };

  const copyHtmlText = () => {
    copyToClipboard(htmlTable);
  };

  const onTextChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (copied) {
      notify();
    }
  }, [copied]);

  return (
    <div className="App container">
      <h1>Markie</h1>
      <p style={{ color: "gray" }}>
        Convert your clipboard table (excel, onenote) to HTML table (into HTML
        string)
      </p>
      <div>
        <form>
          <div class="form-group">
            <textarea
              id="editor"
              rows="10"
              cols="50"
              style={{ width: "100%" }}
              onChange={onTextChange}
            ></textarea>
          </div>
        </form>
        <div>
          <button
            id="copy"
            className="btn btn-primary"
            onClick={convertToHtmlTable}
          >
            Generate
          </button>
          <ToastContainer />
        </div>
        <div>
          {htmlTable && (
            <div>
              <h2>Output</h2>
              <div dangerouslySetInnerHTML={{ __html: htmlTable }}></div>
              <button
                id="copy"
                className="btn btn-success"
                onClick={copyHtmlText}
              >
                Copy
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
